import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { marked } from "marked";
import { sanitize } from "dompurify";
import { useShowError } from "@equiem/web-ng-lib";
import { Card, useTheme } from "@equiem/react-end-user-ui";
import { ASSISTANT } from "./ChatContext";
import { animated, useSpring } from "@react-spring/web";
import { BotAvatar } from "./BotAvatar";
import { ChatFeedbackMessage } from "./ChatMessageFeedback";

export const RichText: React.FC<{ markdown: string }> = ({ markdown }) => {
  const [unsanitizedHTML, setUnsanitizedHTML] = useState("");
  const showError = useShowError();

  useEffect(() => {
    async function fetchData() {
      const parsed = await marked.parse(markdown.replace(/\u3010.*?\u3011/gu, ""));
      setUnsanitizedHTML(parsed);
    }
    fetchData().catch(showError);
  }, [markdown, showError]);

  return <div style={{ marginBottom: "-1rem" }} dangerouslySetInnerHTML={{ __html: sanitize(unsanitizedHTML) }}></div>;
};

interface Props {
  role: string;
  date: Date;
  value: string | ReactNode;
  rawMessage: string;
  previousMessage?: string;
  animate?: boolean;
  isLoader?: boolean;
  showFeedback?: boolean;
}

export const ChatMessage: React.FC<Props> = ({
  value,
  role,
  rawMessage,
  previousMessage,
  animate = true,
  isLoader = false,
  showFeedback = true,
}) => {
  const { colors } = useTheme();

  const style = useSpring({
    from: { scale: 0, opacity: 0 },
    to: { scale: 1, opacity: 1 },
    config: {
      tension: 300,
      friction: 10,
      duration: 200,
    },
  });

  return (
    <animated.div className="d-flex align-items-start mt-2 mb-2" style={animate ? style : undefined}>
      {role === ASSISTANT && <BotAvatar />}
      <div
        style={{
          width: isLoader ? "auto" : "80%",
          marginLeft: `${role !== ASSISTANT ? "auto" : "10px"}`,
        }}
      >
        <Card.Card
          style={{
            backgroundColor: `${role !== ASSISTANT ? colors.blue[20] : colors.greyBackground}`,
            overflowWrap: "break-word",
          }}
        >
          <Card.CardBody>{value}</Card.CardBody>
        </Card.Card>
        {showFeedback && previousMessage != null && (
          <ChatFeedbackMessage question={previousMessage} response={rawMessage} />
        )}
      </div>
    </animated.div>
  );
};
